import { Fragment, useCallback, useState } from 'react';
import { ListActions } from 'react-use/lib/useList';
import { Divider, Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { PartAutocomplete } from '@/src/components/Common/Autocomplete/PartAutocomplete';
import { Part, PartWithQty } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/CreateNewQuoteDrawer';
import { PartToAddCard } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/PartToAddCard';
import { RequestedPart } from '@/src/components/Dashboards/components/CreateNewQuoteDrawer/RequestedPart';
type RequestedPartsProps = {
  selectedParts: PartWithQty[];
  selectedPartsListActions: ListActions<PartWithQty>;
};
export const RequestedParts = ({
  selectedParts,
  selectedPartsListActions
}: RequestedPartsProps) => {
  const [partToAdd, setPartToAdd] = useState<Part | null>(null);
  const onPartClick = useCallback((part: Part | null) => {
    setPartToAdd(part);
  }, []);
  return <>
      <Grid xs={12} lg={6} data-sentry-element="Grid" data-sentry-source-file="RequestedParts.tsx">
        <Typography variant="h4" mb={2} data-sentry-element="Typography" data-sentry-source-file="RequestedParts.tsx">
          Add Parts
        </Typography>
        <PartAutocomplete onPartClick={onPartClick} selectedParts={selectedParts} data-sentry-element="PartAutocomplete" data-sentry-source-file="RequestedParts.tsx" />

        {partToAdd && <PartToAddCard part={partToAdd} key={partToAdd.id} onAddToQuote={(part, qty) => {
        selectedPartsListActions.upsert((a, b) => a.id === b.id, {
          ...part,
          qty
        });
        setPartToAdd(null);
      }} />}
      </Grid>
      {selectedParts.length > 0 && <Grid xs={12} lg={6}>
          <Typography variant="h4" mb={2}>
            Requested Parts
          </Typography>
          <Stack spacing={2}>
            {selectedParts.map((part, index) => {
          const isLast = index === selectedParts.length - 1;
          return <Fragment key={part.id}>
                  <RequestedPart part={part} index={index} selectedPartsListActions={selectedPartsListActions} />
                  {!isLast && <Divider />}
                </Fragment>;
        })}
          </Stack>
        </Grid>}
    </>;
};